import { combineReducers } from 'redux';
// import login from './login/login'
// import vendorData from './vendor/vendorData';
// import profile from './profile/profile';
// import orderprocess from './orderProcess/orderprocess';
import location from './location/locationData';
import searchList from './ListDataForSearch/listDataForSearch';

export default combineReducers({
  location, searchList
});
