import React, { lazy } from "react";
import { Route, Redirect } from "react-router-dom";
const Home = lazy(() => import("./component/Home"));

const pageValidation = (Component, props, pageName) => {
  if (localStorage.getItem(pageName)) {
    if (localStorage.getItem("ferrohaatCareAvailability") === "notAvailable") {
      return <Home {...props} />;
    } else {
      return <Component {...props} />;
    }
  } else {
    return <Redirect to={{ pathname: "/" }} />;
  }
};

const PrivateRoute = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={() =>
      sessionStorage.getItem("jwt_token") ? (
        pageValidation(Component, props, props.pageName)
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
