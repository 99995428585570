import $ from 'jquery';

// $(document).ready(function () {
//   console.log("document loaded");
//   var acc = document.getElementsByClassName("accordion");
//   var i;

//   for (i = 0; i < acc.length; i++) {
//     acc[i].addEventListener("click", function () {
//       /* Toggle between adding and removing the "active" class,
//       to highlight the button that controls the panel */
//       this.classList.toggle("active");

//       /* Toggle between hiding and showing the active panel */
//       var panel = this.nextElementSibling;
//       if (panel.style.display === "block") {
//         panel.style.display = "none";
//       } else {
//         panel.style.display = 'block';
//       }
//     });
//   }

//   // Animated Accordion
//   const accAnimated = document.getElementsByClassName('accordion');
//   for (i = 0; i < acc.length; i++) {
//     accAnimated[i].addEventListener('click', function () {
//       this.classList.toggle('active');
//       const panel = this.nextElementSibling;
//       if (panel.style.maxHeight) {
//         panel.style.maxHeight = null;
//       } else {
//         panel.style.maxHeight = `${panel.scrollHeight}px`;
//       }
//     });
//   }
// });

export const acc = (cls) => {
  $(document).ready(() => {
    const acc = document.getElementsByClassName(cls);
    const accordion = document.getElementsByClassName(`accordion${cls}`);
    const panel = accordion[0].nextElementSibling;
    if (panel.style.display === 'block') {
      panel.style.display = 'none';
      panel.style.maxHeight = null;
    } else {
      panel.style.display = 'block';
      panel.style.maxHeight = `${panel.scrollHeight}px`;
    }
  });
};
