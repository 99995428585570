import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import Routes from './routes';
import configureStore from './redux/config';
import './static/css/table.css';
import './static/css/main.css';
import 'react-dropdown-now/style.css';
import './static/js/accordion';
import 'jquery';
import 'bootstrap/dist/js/bootstrap';

ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
