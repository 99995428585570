import {
  ERROR,
  PENDING,
  FIELD_LIST_SEARCH_SUCCESS,
} from "../../action/location/type";

const initialState = {
  vendorCodeList: [],
  vendorNameList: [],
  orderIdList: [],
  orderReferenceId: [],
  dumperTruckList: [],
  activeTruckList: [],
  ewbNoList:[],
  shipmentIdList:[],
};

export default (state = initialState, action) => {
  // console.log('In Search Reducer');

  switch (action.type) {
    case FIELD_LIST_SEARCH_SUCCESS:
      // console.log(
      //   `In Search Reducer`,
      //   [action.payload.vendorCode],
      //   [action.payload.vendorName],
      //   [action.payload.orderId],
      //   [action.payload.orderReferenceId]
      // );
      return {
        ...state,
        vendorCodeList: [...action.payload.vendorCode],
        vendorNameList: [...action.payload.vendorName],
        orderIdList: [...action.payload.orderId],
        orderReferenceId: [...action.payload.orderReferenceId],
        dumperTruckList: [...action.payload.dumperTrucks],
        activeTruckList: [...action.payload.activeTrucks],
        ewbNoList: [...action.payload.ewb],
        shipmentIdList: [...action.payload.shipmentId]

      };
    case PENDING:
      return {
        ...state,
        logindata: [],
        isLoading: true,
        isError: false,
        error: "Some Error Occurred.", // action.error,
      };
    case ERROR:
      return {
        ...state,
        logindata: [],
        isLoading: false,
        isError: true,
        error: "Some Error Occurred.", // action.error,
      };
    default:
      return state;
  }
};
